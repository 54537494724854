import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import SubNav from "../dashboard/SubNav";

const Dashboard = () => {
  return (
    <Fragment>
      <main className="dashboard">
        <Outlet />
        <SubNav />
      </main>
    </Fragment>
  );
};

export default Dashboard;
