import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import classes from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={classes.container}>
      <Skeleton
        style={{
          maxWidth: "80rem",
          height: "60rem",
          display: "block",
          margin: "auto",
        }}
        variant="rectangular"
      />
    </div>
  );
};

export default Loading;
