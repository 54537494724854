import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Helmet } from "react-helmet-async";

import classes from "./Investments.module.css";
import { getInvestments } from "../../api/api";
import Investment from "./Investment";

const Investments = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [investments, setInvestments] = useState([]);
  const { jwt } = useCookies(["jwt"])[0];
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchInvestments = async () => {
      const res = await getInvestments(jwt);
      if (res.status === "success") {
        setInvestments(res.data.investments);
        setIsLoading(false);
      }
    };
    fetchInvestments();
  }, [jwt, reload]);

  if (investments.length === 0) {
    return (
      <div className={classes.empty}>
        <p>You have no investments yet in this category</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <Skeleton
          variant="rectangular"
          width="100"
          height="60rem"
          baseColor="#087f5b"
          highlightColor="#94d82d"
        />
      </div>
    );
  }
  return (
    <div className={classes.investments}>
      <Helmet>
        <title>INVESTMENTS</title>
        <meta name="description" content={`List of open investments`} />
        <link rel="canonical" href="/dashbaord/investments" />
      </Helmet>
      {investments.map((el) => (
        <Investment
          key={el._id}
          id={el._id}
          name={el.plan.name}
          investedAmount={el.investedAmount}
          startDate={el.startDate}
          endDate={el.endDate}
          createdAt={el.createdAt}
          contractLength={el.contractLength}
          status={el.status}
          withdrawStatus={el.withdrawStatus}
          wallet={el.wallet}
          updatedAt={el.updatedAt}
          returns={el.returns}
          weeklyReturns={el.weeklyReturns}
          investor={el.user.name}
          onReload={setReload}
        />
      ))}
    </div>
  );
};

export default Investments;
