import { useState } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import { useCookies } from "react-cookie";

import classes from "./UserItem.module.css";
import { deleteUser } from "../../api/api";
import Spinner from "../UI/Spinner";
import AuthAlert from "../alerts/AuthAlert";

const UserItem = ({ name, email, country, id, onReload }) => {
  const { jwt } = useCookies(["jwt"])[0];
  const [showSpinner, setShowSpinner] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const deleteHandler = async () => {
    setShowSpinner(true);

    const res = await deleteUser(id, jwt);

    if (!res.status) {
      setAlertMsg("User deleted successfully!");
      setAlertStatus(true);
      setShowAlert(true);
      onReload((prevState) => !prevState);
    } else {
      setAlertMsg(res.message);
      setAlertStatus(false);
      setShowAlert(true);
    }
    setShowSpinner(false);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  };
  return (
    <div className={classes.user}>
      {showSpinner && <Spinner />}
      {showAlert && <AuthAlert message={alertMsg} status={alertStatus} />}
      <div className={classes.content}>
        <span>Name</span>
        <span>{name}</span>
      </div>
      <div className={classes.content}>
        <span>Email</span>
        <span>{email}</span>
      </div>
      <div className={classes.content}>
        <span>Country</span>
        <span>{country}</span>
      </div>
      <div className={classes.action}>
        <div className={classes.action}>
          <FaRegTrashCan className={classes.icon} onClick={deleteHandler} />
        </div>
      </div>
    </div>
  );
};

export default UserItem;
