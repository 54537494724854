import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import classes from "./Header.module.css";
import logo from "../../images/logo.png";
import { authActions } from "../../store/authSlice";

const Header = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const setCookie = useCookies(["jwt"])[1];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    setCookie("jwt", "loggedOut");
    dispatch(authActions.logout());
  };

  const gohomeHandler = () => {
    navigate("/");
  };
  return (
    <header className={classes.header}>
      <div className={classes.logo} onClick={gohomeHandler}>
        <img src={logo} alt="logo" />
      </div>
      {!isLoggedIn && <Link to="login">Login</Link>}
      {isLoggedIn && (
        <Link to="#" onClick={logoutHandler}>
          Logout
        </Link>
      )}
    </header>
  );
};

export default Header;
