import classes from "./Home.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Home = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <section className={classes.home}>
      <Link to={isLoggedIn ? "dashboard" : "login"} className={classes.action}>
        Get Started
      </Link>
    </section>
  );
};

export default Home;
