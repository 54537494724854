import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// const axiosInstance = axios.create({
//   baseURL: "https://api.orohandel.com/api/v1/",
// });

export const signIn = async (data) => {
  try {
    const res = await axiosInstance({
      method: "post",
      url: "users/Login",
      data,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export const preSignIn = async (data) => {
  try {
    const res = await axiosInstance({
      method: "post",
      url: "users/preLogin",
      data,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const createAccount = async (data) => {
  try {
    const res = await axiosInstance({
      method: "post",
      url: "users/signUp",
      data,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const signOut = async () => {
  try {
    const res = await axiosInstance({
      method: "POST",
      url: "users/logout",
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const forgotPassword = async (data) => {
  try {
    const res = await axiosInstance({
      method: "post",
      url: "users/forgotPassword",
      data,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const resetPassword = async (data, token) => {
  try {
    const res = await axiosInstance({
      method: "POST",
      url: `users/resetPassword/`,
      data,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getMe = async (jwt) => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: `users/me`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getUsers = async (jwt) => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: `users/getAllUsers`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const deleteUser = async (id, jwt) => {
  try {
    const res = await axiosInstance({
      method: "DELETE",
      url: `users/delete/${id}`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getPlans = async () => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: `plans/getAll`,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const invest = async (data, jwt) => {
  try {
    const res = await axiosInstance({
      method: "post",
      url: "investments/create",
      data,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const updatePassword = async (data, jwt) => {
  try {
    const res = await axiosInstance({
      method: "PATCH",
      url: "users/updatePassword",
      data,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getInvestments = async (jwt) => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: `investments/getAll`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const approveInvestment = async (id, jwt) => {
  try {
    const res = await axiosInstance({
      method: "PATCH",
      url: `investments/approve/${id}`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const approveWithdraw = async (id, jwt) => {
  try {
    const res = await axiosInstance({
      method: "PATCH",
      url: `investments/approveWithdraw/${id}`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const deleteInvestment = async (id, jwt) => {
  try {
    const res = await axiosInstance({
      method: "DELETE",
      url: `investments/delete/${id}`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getWallet = async (jwt) => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: `wallets/getAll`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const updateWallet = async (data, id, jwt) => {
  try {
    const res = await axiosInstance({
      method: "PATCH",
      url: `wallets/update/${id}`,
      data,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const emailAdmin = async (data) => {
  try {
    const res = await axiosInstance({
      method: "POST",
      url: `plans/email`,
      data,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
